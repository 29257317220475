class Intl {
  static supportedLanguages = { 'en': 'english', 'de': 'deutsch' };
  static availableLanguages = ['en', 'de'];
  static languageForCode = x => Intl.supportedLanguages[x.slice(0,2)];
  static selectedLanguage = 'de'; // default language
  
  static setLanguage(language) {
    Intl.selectedLanguage = language;
  }
  
  static setLanguageInitially(setLanguageCallback=null) {
    let setToLanguage = null;
    
    // read from ?param if given
    const search = window.location.search;
    if (search && search.length > 0) {
      setToLanguage = search.substring(1).toLowerCase();
      window.history.replaceState({}, document.title, window.location.pathname); // remove ?parameter
    }
    
    // read from local storage if available
    const localStorageLanguage = localStorage.getItem('lang');
    if (!setToLanguage && localStorageLanguage) setToLanguage = localStorageLanguage;
    
    if (!setToLanguage) setToLanguage = Intl.selectedLanguage; // use default if none other given
    
    if (setToLanguage && Intl.availableLanguages.includes(setToLanguage)) {
      if (setLanguageCallback) setLanguageCallback(setToLanguage); // inform caller
      if (localStorageLanguage !== setToLanguage)
        localStorage.setItem('lang', setToLanguage);
      Intl.setLanguage(setToLanguage);
    }
  }
  
  // translate text (argument is list of texts according)
  static tl(textList) {
    if (Intl.selectedLanguage === 'en') return textList[0];
    else if (Intl.selectedLanguage === 'de') return textList[1];
    else return '<MISSING>';
  }
  
  // translate text (arguments are individual texts)
  static t(enText, deText) {
    return Intl.tl([enText, deText]);
  }
}

const t = (a,b) => Intl.t(a,b);
const tl = lst => Intl.tl(lst);
export { t, tl };

export default Intl;