import React, { Component } from 'react';
import AudioElement from './AudioElement.js';
import ErrorHandler from './ErrorHandler.js';
import { t, tl } from './Intl.js';
import { MediaDeviceInterface } from './Utils.js';
import { ImPlay3, ImPause2, ImCross, ImArrowRight, ImArrowLeft, ImSpinner11, ImCompass } from "react-icons/im";
import './AudioPlay.css';

class AudioTimer {
  constructor(fireCallback) {
    this.fireCallback = fireCallback;
    this.timer = null;
  }
  
  run() {
    if (!this.timer) {
      const self = this;
      this.timer = setInterval(() => {
        self.fireCallback();
      }, 500);
    }
  }
  
  stop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
}


/*function formatTime(audioTime) {
  const current = Math.floor(audioTime);
  const minutes = Math.floor(current / 60);
  const seconds = current - 60 * minutes;
  return ('0' + minutes).substr(-2) + ':' + ('0' + seconds).substr(-2);
}*/

class AudioPlay extends Component {
  
  constructor() {
    super();
    
//    // DUMMY
//    this.ii = 0;
//    this.iiclips = [
//      [30, 'https://zurich.bywalking.com/admin-server/audio/audio/4/30/9e7e4c7009e43dc927e3604107ee4f04'],
//      [31, 'https://zurich.bywalking.com/admin-server/audio/audio/4/31/9e7e4c7009e43dc927e3604107ee4f04'],
//    ];
    
    this.clipHistory = null; // will be overriden to point to parent clipHistory
    this.queue = null;
    this.lastAudioQueue = null;
    this.outroClips = null;
//    this.waitBetweenClips = 1.5; // in seconds
    this.audioElementRef = React.createRef();
    this.audioSilentRef = React.createRef();
    this.mediaDeviceInterface = null;
    this.setUpMediaPlayer();
    
    const self = this;
    this.audioTimer = new AudioTimer(() => {
      let progress = null;
      const audio = self.audioElementRef?.current?.audioRef?.current;
      if (audio) progress = audio.currentTime / audio.duration;
        self.setState(Object.assign({}, self.state, {audioProgress: progress}));
    });
    
    const _cityLabel = 'zurich';
    const _cityFromLabel = {
      zurich: ['Zurich', 'Zürich']
    };
    
    this.state = {
      cityLabel: _cityLabel,
      city: _cityFromLabel[_cityLabel] || ['Guide', 'Guide'],
      nowPlaying: null,
      audioProgress: null,
    };
    
    this.changeJourney = this.changeJourney.bind(this);
  }
  
  componentDidMount() {
    this.mediaDeviceInterface = new MediaDeviceInterface(this.audioElementRef?.current?.audioRef?.current);
  }
  
  updateMediaPositionState() {
    if (this.mediaDeviceInterface) this.mediaDeviceInterface.updatePosition();
  }
  
  setUpMediaPlayer() {
    const actionsAndHandlers = [
      ['play', () => { this.changeJourney('play'); }],
      ['pause', () => { this.changeJourney('play:paused'); }],
      ['seekbackward', (details) => {
        const skipTime = details.seekOffset || 10; // default is 10s
//        audioElement.currentTime = Math.max(video.currentTime - skipTime, 0);
        this.rewind(skipTime); 
      }],
      ['seekforward', null], //(details) => {
//        alright.currentTime = alright.currentTime + (details.seekOffset || 10);
//        this.updateMediaPositionState();
//      }],
      ['seekto', null], //(details) => {
//        if (details.fastSeek && 'fastSeek' in alright) {
//          alright.fastSeek(details.seekTime);
//        this.updateMediaPositionState();
//          return;
//        }
//        alright.currentTime = details.seekTime;
//        this.updateMediaPositionState();
//      }],
      ['stop', null], //() => {
//        alright.pause();
//        alright.currentTime = 0;
//      }],
    ];
    
    MediaDeviceInterface.setUp(actionsAndHandlers);
  }
  
  resetQueue() {
    this.queue = null;
    this.stopPlaying();
  }
  
  audioQueueHasChanged(updatedAudioQueue) {
    if (!updatedAudioQueue?.queue) {
      this.queue = []; // empty queue
    }
    
    else {
      if (this.clipHistory === null) ErrorHandler.raise('Clip history must not be null');

      // delta: How many more clips have been added to clip history while waiting for server response
      const delta = this.clipHistory.length - updatedAudioQueue.serverState.visitHistoryLength;
      this.queue = updatedAudioQueue.queue.slice(delta); // ignore items in the queue that have been added to history
    }
//      if (this.state.nowPlaying === null) this.playNextInQueue(); // start playing
    this.lastAudioQueue = updatedAudioQueue;
  }
  
  startPlaying() {
    this.playNextInQueue();
  }
  
  stopPlaying() {
    this.audioTimer.stop();
    this.audioElementRef.current.pause();
    this.setState(Object.assign({}, this.state, {nowPlaying:null})); // not playing anything
  }
  
  rewind(seconds=10) {
    this.audioElementRef.current.rewind(seconds);
    this.updateMediaPositionState();
  }
  
  playNextInQueue() {
    if (this.queue === null) {
      this.audioTimer.stop();
      return; // no more clips in queue
    }
    
    let clipsToAddToHistory = [], nextClip = null;
    while(this.queue.length > 0) { // while has more items to check
      const candidate = this.queue.shift();
      clipsToAddToHistory.push(candidate); // pop 0-th element from queue
      // if last selected clip is no silent step, this will be played now
      if (Number.isInteger(candidate.audio)) {
        nextClip = candidate; // last one
        break; // if no silent step, leave loop
      }
    }
    if (clipsToAddToHistory.length > 0) this.clipHistory.push(...clipsToAddToHistory);
    
    if (nextClip !== null) {
      this.play(nextClip);
//      if (!this.state.nowPlaying) this.play(nextClip);
//      else {
//        const self = this;
//        setTimeout(() => {
//          self.play(nextClip);
//        }, this.state.nowPlaying ? this.waitBetweenClips * 1000 : 0); // wait if not first clip
//      }
    }
    else this.stopPlaying();
  }
  
  
  playDUMMY() {
    const cur = this.iiclips[this.ii%this.iiclips.length];
//    console.log('Dummy play', cur[1]);
    this.audioElementRef.current.loadAndPlay(cur[1]);
//    this.play({room:1, location:'Züri', clip:1, audio:cur[0], title:'Titel'});
//    if (this.ii === 0) this.audioSilentRef.current.loadAndPlay('https://github.com/anars/blank-audio/blob/master/1-second-of-silence.mp3?raw=true');
    this.ii += 1;
  }
  
  play(clip) {
//    console.log('Play', clip);
    const clipUrl = 'https://zurich.bywalking.com/admin-server/audio/audio/' + this.props.guide + '/' + clip.audio + '/9e7e4c7009e43dc927e3604107ee4f04';
    
    const album = clip?.title;
    const artist = (clip?.location && album.includes(clip?.location)) ? null : clip?.location;
    const title = tl(this.state.city) + ' by Walking';
    const artwork = [
      { src: '/artwork/xbywalking-256.png', sizes: '256x256', type: 'image/png' },
      { src: '/artwork/xbywalking-512.png', sizes: '512x512', type: 'image/png' },
    ];
    MediaDeviceInterface.setSong(artist, album, title, artwork);
    
    this.setState({...this.state, nowPlaying: clip});
    this.audioElementRef.current.loadAndPlay(clipUrl);
    this.audioTimer.run(); // ensure timer is running
    
    if (this.audioSilentRef.current.notPlayedYet) { // play only once
//      console.log('Play silent audio once');
      this.audioSilentRef.current.loadAndPlay(null, true);
    }
  }
  
  handleFinishedPlaying() {
//    this.playDUMMY(); return;
    
    if (this.props.journey === 'play:outro') this.changeJourney('startover');
    else this.playNextInQueue();
  }
  
  changeJourney(newJourneyState) {
//    console.log(this.props.journey, '->', newJourneyState);
    if (newJourneyState === 'play') {
      if (this.props.journey === 'play:paused') { 
        this.audioElementRef.current.resume();
        this.audioTimer.run(); // make sure is running
      }
      this.updateMediaPositionState();
    }
    else if (newJourneyState === 'play:paused') {
      this.audioElementRef.current.pause();
      this.audioTimer.stop();
    }
    // skip outro phase if there's no outro clip available
    else if (newJourneyState === 'play:outro') {
      // if has no outro clips, move directly into 'exiting'
      if (!this.outroClips || Object.keys(this.outroClips).length === 0) {
        this.changeJourney('startover'); // directly move into exit and startover
        return; // jump out, re-do with status 'exiting'
      }
      // otherwise play outro clip
      else {
        const outroKeys = Object.keys(this.outroClips);
        const outroClip = outroKeys[Math.floor(Math.random() * outroKeys.length)];
        this.play(this.outroClips[outroClip]);
      }
    }
    // stop everything when exiting and moving into startover
    else if (newJourneyState === 'startover') {
      this.stopPlaying();
//      this.audioElementRef.current.unload();
    }
    
    this.props.triggerJourney(newJourneyState);
  }
  
  renderAudioSignal(progress) {
    const n = 9;
    const boxes = [...Array(n).keys()].map((x,i) => {
      const boxType = (i%2===0) ? 'boxQuiet' : ((i%4===1) ? 'boxNormal' : 'boxLoud');
      return (
        <div key={i} className={'box ' + ((progress !== null && (i+1)>n*progress) ? boxType : 'boxZero')}></div>
      );
    });
    
    return (
      <div className='boxOuter'>
        <div className="boxContainer">
          {boxes}
        </div>
      </div>
    );
  }
  
  render() {
//    const summary = (this.clipHistory ? 'Played ' + this.clipHistory.filter(x => x.clip !== null).map(x => x.clip + ':' + x.audio) + ', ' : '') + (this.queue ? 'In queue ' + this.queue.filter(x => x.clip !== null).map(x => x.clip + ':' + x.audio) : '');
    const visualizeTitle = this.props.journey.slice(0, 4) === 'play' || this.props.journey === 'startover';
    const animateAudio = this.state.nowPlaying && ['play', 'play:confirmExit', 'play:outro'].includes(this.props.journey);
    
    // audio sample files: https://github.com/anars/blank-audio
    return (
      <div>
        <AudioElement ref={this.audioSilentRef} />
        <AudioElement ref={this.audioElementRef} handleClipEnded={_ => this.handleFinishedPlaying()} />
        
        {false &&
        <div style={{fontSize:'30pt', backgroundColor: 'rgb(0,0,255)', cursor: 'pointer'}} onClick={() => this.playDUMMY()}>PLAY DUMMY</div>
        }
        {false &&
        <div>Journey {this.props.journey}, visualize {0+visualizeTitle}</div>
        }
        
        <div style={{marginTop: '20px', marginBottom: '20px'}}>
          <div style={{fontSize: '10pt', textTransform: 'uppercase', letterSpacing: '3px'}}><ImCompass /> <span>{this.state.nowPlaying?.location ?? tl(this.state.city)}</span></div>
          {visualizeTitle &&
          <div style={{marginTop: '20px', fontSize: '18pt'}}>{this.state.nowPlaying?.title ?? <span style={{visibility:'hidden'}}>X</span>}</div>
          }
        </div>
        
        {visualizeTitle &&
        <div>
        {this.renderAudioSignal(animateAudio ? this.state.audioProgress : null)}
        </div>
        }
        
        {visualizeTitle && !['play:outro', 'exiting', 'startover'].includes(this.props.journey) &&
        <div style={this.props.boxStyle}>
          {this.props.journey === 'play' && <span><button onClick={() => this.rewind()}><ImSpinner11 style={{verticalAlign: 'middle', WebkitTransform: 'scaleX(-1)', transform: 'scaleX(-1)'}} /> 10s</button></span>}
          {this.props.journey === 'play' && <span><button onClick={() => this.changeJourney('play:paused')}>Pause <ImPause2 style={{verticalAlign: 'middle'}} /></button></span>}
          {this.props.journey === 'play:paused' && <span>{t('Paused', 'Pause')} <button onClick={() => this.changeJourney('play')}>{t('Continue', 'Weiter')} <ImPlay3 style={{verticalAlign: 'middle'}} /></button></span>}
          {this.props.journey === 'play' && <span><button onClick={() => this.changeJourney('play:confirmExit')}>{t('Exit', 'Ende')} <ImCross style={{verticalAlign: 'middle'}} /></button></span>}
          {this.props.journey === 'play:confirmExit' && 
            <span>
            <span><button onClick={() => this.changeJourney('play')}><ImArrowLeft disabled={this.props.journey === 'play:outro'} /></button></span>
            <span>{t('Leaving?', 'Guide verlassen?')} </span>
            <span><button onClick={() => this.changeJourney('play:outro')}>{t('Exit', 'Ende')} <ImArrowRight style={{verticalAlign: 'middle'}} /></button></span>
            </span>
          }
        </div>
        }
      </div>
    );
  }
}

export default AudioPlay;