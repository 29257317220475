import ErrorHandler from './ErrorHandler.js';

class GeoLocation {
  static createInstance(onUpdateCallback, simulate=false) {
    if (simulate) return new SimulationGeoLocation(onUpdateCallback);
    else return new BrowserGeoLocation(onUpdateCallback);
  }
  
  constructor(onUpdateCallback) {
    this.onUpdateCallback = onUpdateCallback;
    this.paused = false;
  }
  
  pause() {
    if (!this.paused) {
      this.paused = true;
      this.stopTrackingLocation();
    }
  }
  
  unpause() {
    if (this.paused) {
      this.paused = false;
      this.startTrackingLocation();
    }
  }
}


class BrowserGeoLocation extends GeoLocation {
  
  constructor(onUpdateCallback) {
    super(onUpdateCallback);
    this.watchHandle = null;
  }
  
  initialize() {
    if ('geolocation' in navigator) {
      this.onUpdateCallback('requested', null);
      navigator.geolocation.getCurrentPosition(position => {
        this.onUpdateCallback('initialPosition', position.coords);
      }, (error) => {
        ErrorHandler.raise(error);
        this.onUpdateCallback('blocked', error);
      });
    }
    else {
      this.onUpdateCallback('notavailable', null);
    }
  }
  
  startTrackingLocation() {
    this.watchHandle = navigator.geolocation.watchPosition(position => {
      // if paused keep tracking location, but do not forward new locations
      if (!this.paused) this.onUpdateCallback('updatedPosition', position.coords);
    });
  }
  
  stopTrackingLocation() {
    if (this.watchHandle) {
      navigator.geolocation.clearWatch(this.watchHandle);
      this.watchHandle = null;
//      this.onUpdateCallback('trackingStopped', null);
    }
  }
}


class SimulationGeoLocation extends GeoLocation {
  
  constructor(onUpdateCallback) {
    super(onUpdateCallback);
    
    this.simulationData = [
      [47.370897955959975, 8.544383670403615]
    , [47.370848705582446, 8.544351119068931]
    , [47.37079945520876, 8.544318567736784]
    , [47.37075020483891, 8.544286016407174]
    , [47.3707009544729, 8.5442534650801]
    , [47.37065170411073, 8.544220913755563]
    , [47.3706024537524, 8.544188362433564]
    , [47.370553203397904, 8.544155811114102]
    , [47.37050395304725, 8.544123259797177]
    , [47.37045470270043, 8.544090708482788]
    , [47.370405452357446, 8.544058157170936]
    , [47.37035620201831, 8.54402560586162]
    , [47.370306951683006, 8.543993054554843]
    , [47.37025770135154, 8.543960503250602]
    , [47.37020845102391, 8.5439279519489]
    , [47.37015920070012, 8.543895400649733]
    , [47.37013501096162, 8.543879412787028]
    , [47.37012482934704, 8.543801171112305]
    , [47.37011464773341, 8.543722929444865]
    , [47.37010446612072, 8.54364468778471]
    , [47.37009428450899, 8.543566446131837]
    , [47.3700841028982, 8.543488204486248]
    , [47.37007392128836, 8.543409962847942]
    , [47.370063739679466, 8.54333172121692]
    , [47.37005355807152, 8.54325347959318]
    , [47.37004337646452, 8.543175237976724]
    , [47.37003319485847, 8.543096996367552]
    , [47.370023013253366, 8.543018754765663]
    , [47.37001283164921, 8.542940513171057]
    , [47.370002650046004, 8.542862271583735]
    , [47.36999246844375, 8.542784030003695]
    , [47.369982286842436, 8.542705788430938]
    , [47.369975152400954, 8.542650963074369]
    ];
    
    this.step = 0;
    this.timeStepSeconds = 3; // seconds until next coordinate
  }
  
  currentCoordinate() {
    const coord = this.simulationData[this.step];
    return {latitude: coord[0], longitude: coord[1]};
  }
  
  initialize() {
    this.onUpdateCallback('requested', null);
    setTimeout(() => {
      // first position will be consumed twice (in initialize and when startTrackingLocation)
      this.onUpdateCallback('initialPosition', this.currentCoordinate());
    }, 1000); // slight delay to give requestor an opportunity to consume 'requested' event
  }
  
  startTrackingLocation() {
    if (this.timer) {
      ErrorHandler.raise('Warning: Timer already running and still requested to start new timer? Will ignore request.');
      return;
    }
    
    const self = this;
    setTimeout(() => {
      self.iterate(); // first one almost immediately
      self.timer = setInterval(() => {
        self.iterate();
      }, self.timeStepSeconds * 1000);
    }, 500);
  }
  
  iterate() {
    if (!this.paused) this.onUpdateCallback('updatedPosition', this.currentCoordinate());
    
    // next step
    if (this.step+1 < this.simulationData.length) this.step += 1; // next step
    // end timer and inform client
    else this.stopTrackingLocation();
  }
  
  stopTrackingLocation() {
    if(this.timer) {
      const timer = this.timer;
      this.timer = null;
      clearInterval(timer);
    }
//    this.onUpdateCallback('trackingStopped', null);
  }
}

export default GeoLocation;