import { Component } from 'react';
//import GoogleMapReact, { fitBounds } from 'google-map-react';
import GoogleMapReact from 'google-map-react';


class LocationMarker extends Component {
  render() {
    return (
      <div style={{width: 15, height: 15, borderRadius: '50%', backgroundColor: 'rgb(114, 114, 170)', border: '3px solid white'}}></div>
    );
  }
}


function createMapOptions(maps) {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      zoomControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      draggable: false,
      styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
    }
  }

class CurrentPositionMap extends Component {
  
  constructor() {
    super();
    
    this.state = {
    };
    
  }
  
  
  
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: this.props.height, width: '100%' }} id='mapContainer'>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDqWTC1vUEXhG0H3Xo2nrIzPP6qMh3821E' }}
          center={{lat:this.props.position[0], lng:this.props.position[1]}}
          zoom={17}
          onClick={null}
          options={createMapOptions}
        >
        
        <LocationMarker lat={this.props.position[0]} lng={this.props.position[1]} />
        
        </GoogleMapReact>
      </div>
    );
  }
}

export default CurrentPositionMap;