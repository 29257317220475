const serverHost = (process.env.NODE_ENV !== 'production') ? 'http://localhost:31593' : 'https://zurich.bywalking.com/admin-server';

class FetchUtil {
  
  static getJSON(url) {
    return fetch(serverHost + url, {method: 'GET', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}})
    .then(function(response) {
      return response.json();
    });
  }
  
  static postJSON(url, jsonContent) {
    return fetch(serverHost + url, {method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, body: jsonContent })
    .then(function(response) {
      return response.json();
    });
  }
  
  static putJSON(url, jsonContent) {
    return fetch(serverHost + url, {method: 'PUT', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}, body: jsonContent })
    .then(function(response) {
      return response.json();
    });
  }
  
  static serverHost() {
    return serverHost;
  }
}


class MediaDeviceInterface {
  
  constructor(htmlMediaElement) {
    this.htmlMediaElement = htmlMediaElement;
    
    if ('mediaSession' in navigator) {
      this.htmlMediaElement.addEventListener('play', () => {
        navigator.mediaSession.playbackState = 'playing';
      });
      this.htmlMediaElement.addEventListener('pause', () => {
        navigator.mediaSession.playbackState = 'paused';
      });
    }
  }
  
  static setSong(artist, album, title, artwork) { // artist can be null
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new window.MediaMetadata({...{
        title: title,
        album: album,
        artwork: artwork
      }, ...(artist!==null && {artist: artist})}); // add artist only when in fact given
    }
  }
  
  updatePosition() {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.setPositionState({
        duration: this.htmlMediaElement.duration,
        playbackRate: this.htmlMediaElement.playbackRate,
        position: this.htmlMediaElement.currentTime
      });
    }
  }
  
  static setUp(actionsAndHandlers) {
    if ('mediaSession' in navigator) {
      for (const [action, handler] of actionsAndHandlers) {
        try {
          navigator.mediaSession.setActionHandler(action, handler);
        } catch (error) {
          // do nothing, ignore unsuppotted media session action
//          console.log('Warning: The media session action ' + action + ' is not supported');
        }
      }
    }
  }
}


export { FetchUtil, MediaDeviceInterface };