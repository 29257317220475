// https://stackoverflow.com/questions/69502340/ios-15-web-audio-playback-stops-working-in-safari-after-locking-screen-for-a-fe
// https://stackoverflow.com/questions/54462253/can-html5-in-the-browser-play-continuous-audio-on-ios-lock-screen

import React, { Component } from 'react';
import ErrorHandler from './ErrorHandler.js';


const silentAudioData = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
//const silentAudioData = '/audio/5-seconds-of-silence.mp3';


class AudioElement extends Component {
  
  constructor() {
    super();
    
    this.audioRef = React.createRef();
//    this.needsCallbackSetup = true;
    this.notPlayedYet = true;
  }
  
  componentDidMount() {
//    const origevents = ['abort' ,'canplay' ,'canplaythrough' ,'durationchange' ,'emptied' ,'ended' ,'error' ,'loadeddata' ,'loadedmetadata' ,'loadstart' ,'pause' ,'play' ,'playing' ,'progress' ,'ratechange' ,'seeked' ,'seeking' ,'stalled' ,'suspend' ,'timeupdate' ,'volumechange' ,'waiting'];
//    const events = origevents.filter(x => !['canplay', 'canplaythrough', 'ended', 'error'].includes(x));
//    events.forEach(x => {
//      this.audioRef.current.addEventListener(x, e => { console.log('Audio event: ', x, e)});
//    });
    
//    this.audioRef.current.addEventListener('canplay', _ => { console.log('canplay')});
    this.audioRef.current.addEventListener('canplaythrough', (event) =>  { this.play()});
    this.audioRef.current.addEventListener('ended', (event) => { if (this.props.handleClipEnded) this.props.handleClipEnded(event) });
    this.audioRef.current.addEventListener('error', (error) => ErrorHandler.raise('Audio error', error, error?.currentTarget?.error));
  }
  
  loadAndPlay(url, playSilence=false) {
    if (!this.audioRef.current) {
      ErrorHandler.raise('Current reference not set up, cannot play yet');
      return;
    }
    
//    console.log('Loading', playSilence ? 'silence' : url, '/', playSilence);
    this.audioRef.current.src = (playSilence ? silentAudioData : url);
    this.audioRef.current.load();
  }
  
  play() {
    const promise = this.audioRef.current.play();
    this.notPlayedYet = false;
    if (promise !== undefined) {
      promise.then(_ => {
//        console.log('Started playing, would be safe to pause now');
      })
      .catch(error => {
        ErrorHandler.raise('Caught error when trying to play audio', error);
      });
    }
  }
  
  pause() {
    this.audioRef.current.pause();
//    console.log('Pause', this.audioRef.current.readyState);
//    const promise = this.audioRef.current.pause();
//    if (promise !== undefined) {
//      promise.then(_ => {
//        console.log('Did pause, would be safe to play now');
//      })
//      .catch(error => {
//        ErrorHandler.raise('Caught error when trying to pause audio', error);
//      });
//    }
  }
  
  resume() {
    this.play();
  }
  
  rewind(seconds) {
    if (this.audioRef.current && !this.audioRef.current.paused) {
      this.audioRef.current.currentTime = Math.max(0, this.audioRef.current.currentTime - seconds);
    }
  }
  
  unload() {
    this.pause();
    this.audioRef.current.src = null; //silent;
  }
  
  render() {
    return (
      <audio ref={this.audioRef} controls={false}></audio>
    );
  }
}

export default AudioElement;