import { Component } from 'react';
import { slide as BurgerMenu } from "react-burger-menu";
import Intl from './Intl.js';
import './Menu.css';


class Menu extends Component {
  
  render() {
    return (
      <div>
        <GenericMenu selectedLanguage={this.props.language} onSelectLanguage={this.props.onSelect} pageWrapId='page-wrap' outerContainerId='App' targetUrl={this.props.targetUrl} languageOnly={this.props.languageOnly} />
        
        <main id='page-wrap'>
          {this.props.children}
        </main>
      </div>
    );
  }
}


class GenericMenu extends Component {
  
  render() {
    const _targetUrl = this.props.targetUrl ?? '';
    
    // if languageOnly is true, only show buttons to change the language
    const items = (this.props.languageOnly ?? false) ? [null] : [
      null,
      ['Home', _targetUrl + Intl.t('/home/?en', '/home/?de')],
      [Intl.t('Share the love', 'Zeig deine Liebe'), _targetUrl + Intl.t('/share/?en', '/share/?de')],
      [Intl.t('Get in touch', 'Schreib uns'), _targetUrl + Intl.t('/contact/?en', '/contact/?de')],
      [Intl.t('Who we are', 'Wer wir sind'), _targetUrl + Intl.t('/aboutus/?en', '/aboutus/?de')],
    ];
    
    const renderLanguageButtons = Intl.availableLanguages.map((x, i) => {
      return (
        <span key={i} className={'lang-button' + (this.props.selectedLanguage === x ? ' lang-selected' : '')} onClick={() => this.props.onSelectLanguage(x)}>{x.toUpperCase()}</span>
      );
    });
    
    return (
      <BurgerMenu {...this.props} >
        {items.map((x,i) => {
          if (i === 0) return (
            <div key={i}>{renderLanguageButtons}</div>
          );
          return (
            <a key={i} className="menu-item" alt={x[0]} href={x[1]}>{x[0]}</a>
          );
        })}
      </BurgerMenu>
    );
  }
}

export default Menu;