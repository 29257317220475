import { FetchUtil } from './Utils.js';
import assert from 'assert';


class ServerConnection {
  
  static guidesCloseby(positionLatLon) {
    assert(positionLatLon.hasOwnProperty('length') && positionLatLon.length === 2, `Attribute positionLatLon length is expected to be 2, but is ${positionLatLon.length}`);
    
    const request = {coordinate: positionLatLon};
    return FetchUtil.postJSON('/bridge/guidesCloseby/', JSON.stringify(request));
  }
  
  static createSession(guide) {
    const clientName = 'client-' + (Math.floor(Math.random() * 999) + 100);
    const request = {user: 'myusername', password: 'mypass', clientName: clientName, guide: guide, includeDebuggingData: false};
    return FetchUtil.postJSON('/bridge/createSession/', JSON.stringify(request));
  }
  
  static sendPulse(session, pulseData) {
    const request = { session: session, pulseData: pulseData };
    return FetchUtil.postJSON('/bridge/pulse', JSON.stringify(request));
  }
  
  static terminateSession(session) {
    const request = {session: session};
    return FetchUtil.postJSON('/bridge/terminateSession/', JSON.stringify(request));
  }
  
}

export default ServerConnection;