import React, { Component, useState } from 'react';
import './App.css';
import AudioPlay from './AudioPlay.js';
import GeoLocation from './GeoLocation.js';
import ServerConnection from './ServerConnection.js';
import CurrentPositionMap from './CurrentPositionMap.js';
import Menu from './Menu.js';
import Popup from './Popup.js';
import ErrorHandler from './ErrorHandler.js';
import Intl, { t } from './Intl.js';
import { ImSpinner8, ImCheckmark } from "react-icons/im";
//import rotatingStyles from './Rotating.module.css';


const hasItems = x => (x) && x.length > 0;
const isLanguageMatch = g => Intl.selectedLanguage === (g?.language ?? '').slice(0,2);

const renderGuideButton = (guide, disabled) => (
  <span {...((!disabled && !isLanguageMatch(guide)) && {className: 'button-with-language-ribbon'})}>{guide.name} {(!disabled && !isLanguageMatch(guide)) && <LanguageRibbon language={Intl.languageForCode(guide?.language)} />}</span>
);

const LanguageRibbon = (props) => {
  if (!props.language) return null;
  return (
    <div className="lang-ribbon">{props.language}</div>
  );
}

function partition(array, condition) {
  let pass = [], fail = [];
  array.forEach((e, idx, arr) => (condition(e, idx, arr) ? pass : fail).push(e));
  return [pass, fail];
}

const GuideList = (props) => {
  const [sameLanguageOnly, setSameLanguageOnly] = useState(true);
  
  function renderButtons(guides, active) {
    if (!guides || guides.length === 0) return null;
    
//    const languageMatch = guides.map(g => isLanguageMatch(g));
    // keep all guides if not active and not same language only, or split by language match
    let [toShow, languageNotMatching] = partition(guides, g => isLanguageMatch(g));
    
    toShow.sort(); // alphabetically
    if (!active || !sameLanguageOnly) {
      toShow = toShow.concat(languageNotMatching.sort());
      languageNotMatching = [];
    }
    
    let rendered = toShow.map((g,i) => {
      return (
        <button key={1+i} disabled={!active} onClick={() => props.onSelectGuide(g)}>{renderGuideButton(g, !active)}</button>
      )
    });
    if (sameLanguageOnly && languageNotMatching.length > 0) {
      rendered = rendered.concat(
        <button key={0} className='more-lang-button' onClick={() => setSameLanguageOnly(!sameLanguageOnly)}>+{languageNotMatching.length} {t('more', 'mehr')}</button>
      );
    }
    return rendered;
  }
  
  let buttons = renderButtons(props.guides?.here, true);
  if (!buttons) buttons = renderButtons(props.guides?.closeby, false);
  if (!buttons) buttons = renderButtons(props.guides?.farAway, false);
  return buttons;
};


class App extends Component {
  
  constructor() {
    super();
    
    this.logs = [];
    this.clipHistory = []; // will always point to exactly the same list (child will point to same one)
    this.audioPlayRef = React.createRef();
    
    this.state = {
      currentPosition: null,
      guides: {
        here: null,
        closeby: null,
        farAway: null
      },
      selectedGuide: null,
      journey: (true || process.env.NODE_ENV !== 'production')  ? 'initial' : 'locked', // 'initial' or 'locked'
      geolocationMessage: null,
      serverSession: null,
      audioQueue: null,
      language: null,
      countClips: 0,
    }
    this.jumpBackWhenStartingOver = null;
    
    this.changeJourneyStep = this.changeJourneyStep.bind(this);
    
    const simulateGPS = !window.location.search.includes('real');
    this.geoLocation = GeoLocation.createInstance((message, argument) => {
      if (message === 'requested') {
        this.changeJourneyStep('positionRequested');
      }
      else if (message === 'blocked') {
        const error = argument;
        ErrorHandler.raise(error);
        this.changeJourneyStep('positionBlocked');
      }
      else if (message === 'notavailable') {
      }
      else if (message === 'initialPosition') {
        const position = [argument.latitude, argument.longitude];
        this.changeJourneyStep('gotPosition', {currentPosition: position});
      }
//      else if (message === 'trackingStopped') { } // do nothing
      else if (message === 'updatedPosition') {
        const position = [argument.latitude, argument.longitude];
        this.setState(Object.assign({}, this.state, {currentPosition: position}));
        this.processPulse(position);
      }
      else ErrorHandler.raise('Unknown GeoLocation message', message);
      
      this.setState(Object.assign({}, this.state, {geolocationMessage: message.concat(' ', (new Date()).toLocaleTimeString())}));
      
    }, simulateGPS);
  }
  
  componentDidMount() {
    Intl.setLanguageInitially(language => {
      if (language !== this.state.language)
        this.setState(Object.assign({}, this.state, {language: language}));
    });
    
    this.audioPlayRef.current.clipHistory = this.clipHistory; // make sure child points to same history
    
    if (!('mediaSession' in navigator)) {
      ErrorHandler.raise('Media session not available in browser');
    }
  }
  
  processPulse(updatedPosition) {
    if (this.clipHistory.length === 0) {
      ErrorHandler.raise('Clip history must not be empty when sending pulse, ignore request');
      return;
    }
    
    const pulseData = {
      fingerprint: null,
      audioPlays: this.clipHistory,
      manualOverrideTarget: null,
      geoCoordinate: updatedPosition
    };
    
    if (this.state.serverSession) ServerConnection.sendPulse(this.state.serverSession.session, pulseData)
    .then(response => {
//      console.log('Received audio queue', response?.instructions?.audioQueue);
      if (response?.instructions?.audioQueue) { // otherwise no new instructions, keep old ones
        this.audioPlayRef.current.audioQueueHasChanged(response?.instructions?.audioQueue);
        this.setState(Object.assign({}, this.state, {audioQueue: response?.instructions?.audioQueue}));
      }
    })
    .catch(error => {
      ErrorHandler.raise('Error', error);
    });
  }
  
  changeJourneyStep(newJourneyState, updateOtherState={}) {
    let update = {...{journey: newJourneyState}, ...updateOtherState};
    
    if (newJourneyState === 'play') {
      if (this.state.journey === 'play:paused') this.geoLocation.unpause();
      else if (this.state.journey === 'play:confirmExit') ; // do nothing, location has been tracked in the background anyway
      else this.geoLocation.startTrackingLocation();
    }
    
    else if (newJourneyState === 'play:paused') {
      this.geoLocation.pause();
    }
    
    else if (newJourneyState === 'play:outro') {
      this.geoLocation.stopTrackingLocation(); // stop watching current position of user
    }
    
    else if (newJourneyState === 'startover') { // user confirmed exit
      this.geoLocation.stopTrackingLocation(); // stop watching current position of user
      
      if (this.state.serverSession) {
        ServerConnection.terminateSession(this.state.serverSession.session)
        .then(response => {
          // do nothing
        })
        .catch(error => {
          ErrorHandler.raise('TERMINATE-ERROR', error);
        });
      }
      
      this.clipHistory.length = 0; // reset to zero
      update = {...update, ...{serverSession: null, selectedGuide: null}};
    }
    
    this.setState(Object.assign({}, this.state, update), () => {
      // if we've got the position, get the guides available right here or closeby
      if (newJourneyState === 'gotPosition') {
        const self = this;
        ServerConnection.guidesCloseby(this.state.currentPosition)
        .then(function(response) {
//          console.log('closeby', response);
          if (response.guidesCloseby) {
            let changeTo = 'gotAvailableGuides';
            let setStateTo = {guides: response.guidesCloseby};
            // we got exactly one guide right here, select this one directly without asking the user
            if (response.guidesCloseby.here && response.guidesCloseby.here.length === 1) {
              changeTo = 'guideSelected';
              Object.assign(setStateTo, {selectedGuide: response.guidesCloseby.here[0]});
            }
            self.jumpBackWhenStartingOver = changeTo;
            self.changeJourneyStep(changeTo, setStateTo);
          }
          else {
            ErrorHandler.raise('No guides available', response);
            self.changeJourneyStep('noGuidesAvailable');
          }
        })
        .catch(function(error) {
          ErrorHandler.raise('Error', error);
          self.changeJourneyStep('error', {error: error});
        });
      }
      
      // if we've selected a guide, already ask for creating a session for this client
      else if (newJourneyState === 'guideSelected') {
        ServerConnection.createSession(this.state.selectedGuide.guide)
        .then(response => {
//          console.log('Create session:', response);
          this.clipHistory.length = 0; // reset to zero
          this.clipHistory.push({room: response.treeRoot, location: 'Tree root', clip: null, audio: null, title: null});
          
          this.audioPlayRef.current.outroClips = response.outroClips;
          this.audioPlayRef.current.resetQueue(); // inform AudioPlay that we have a new session
          this.setState(Object.assign({}, this.state, {serverSession: {session: response.session, treeRoot: response.treeRoot, outroClips: response.outroClips}, audioQueue: null}));
          
          // get first pulse to be ready for immediate start when button is clicked
          this.processPulse(this.state.currentPosition);
        })
        .catch(error => {
          ErrorHandler.raise('CREATE//ERROR', error);
        });
      }
    });
  }
  
//  XXXXXXhandleAddToClipHistory(items) {
//    console.log('App: ', this, 'ADD TO CLIP HISTORY', items, 'yields', {...this.state, clipHistory:this.state.clipHistory.concat(items)});
//    if (this.clipHistory === null) ErrorHandler.raise('CLIP HISTORY must not be null when adding items');
////    this.setState({...this.state, clipHistory: this.state.clipHistory.concat(items)});
////    const newState = Object.assign({}, this.state, {clipHistory: JSON.parse(JSON.stringify(this.state.clipHistory.concat(items))), countClips: 2});
//    const newState = Object.assign({}, this.state, {countClips: 2});
//    console.log('NEW STATE', newState);
//    this.setState(newState, _ => {
//      console.log('AFTER SETTING', this.state);
//    });
//  }
//  handleAddToClipHistory() {
//    console.log('THIS', this);
//    console.log('CC', this.state.countClips);
////    setTimeout(_ => {
////      this.setState({...this.state, countClips:3}, _ => {
////        console.log('CC-done', this.state.countClips);
////      });
////    }, 10);
//    const newState = {countClips: 5};
//    this.setState({...this.state, countClips:3}, _ => {
//      console.log('CC-done', this.state.countClips);
//    });
//  }
//  
  render() {
    const boxStyle = {
      marginTop: '15px',
      marginBottom: '15px',
      borderColor: 'rgb(222, 222, 222)',
      borderWidth: '2px',
      backgroundColor: 'rgb(230, 230, 230)',
      fontSize: 'calc(12px + 3vmin)',
      borderRadius: '5px',
      minHeight: '44px',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,20%), 0px 1px 1px 0px rgba(0,0,0,14%), 0px 1px 3px 0px rgba(0,0,0,12%)',
      lineHeight: 2,
    };
    const inactiveBoxStyle = {
      backgroundColor: 'rgb(250,250,250)',
      borderStyle: 'dashed',
      boxShadow: '',
    };
    const infoStyle = {...boxStyle, ...{
      borderColor: 'none',
      backgroundColor: 'none',
      fontSize: 'calc(6px + 3vmin)',
      boxShadow: 'none',
    }};
    
    const whichGuideButtons = hasItems(this.state.guides?.here) ? 'here' : (hasItems(this.state.guides?.closeby) ? 'closeby' : (hasItems(this.state.guides?.closeby) ? 'farAway' : null));
    const noGuidesRightHere = ['closeby', 'farAway'].includes(whichGuideButtons);
    const queueReadyToPlay = this.state.audioQueue?.queue?.length > 0; // ready to play audio
    const link1 = ['initial', 'positionBlocked'].includes(this.state.journey);
//    const active1 = !['play', 'startover', 'play:outro'].includes(this.state.journey);
    const active2 = ['gotAvailableGuides', 'guideSelected'].includes(this.state.journey);
    const active3 = this.state.journey === 'guideSelected' && queueReadyToPlay;
//    const currentlySettingUp = active1 || active2 || active3;
    const currentlyPlaying = this.state.journey.slice(0, 4) === 'play';
    const currentlySettingUp = this.state.journey.slice(0, 4) !== 'play' && this.state.journey !== 'startover' && this.state.journey !== 'locked';
    
    return (
      <div className='App'>
        <header className='App-header'>
          <img style={{maxWidth: '400px', width: '100%', height: 'auto'}} alt='Zurich by Walking' src='/hbywalking.png' />
        </header>
        
        <Popup />
        
        <Menu language={this.state.language} onSelect={lang => {Intl.setLanguage(lang); this.setState(Object.assign({}, this.state, {language:lang}))}} targetUrl='https://www.bywalking.com' languageOnly={currentlyPlaying}>
        </Menu>
        
        {false &&
          <div>Journey {this.state.journey}, geolocation ({0+this.geoLocation.paused}) {this.state.geolocationMessage}, which {whichGuideButtons}</div>
        }
        
        <AudioPlay ref={this.audioPlayRef} journey={this.state.journey} guide={this.state.selectedGuide?.guide} triggerJourney={newJourney => this.changeJourneyStep(newJourney)} boxStyle={boxStyle} />
        
        {this.state.journey === 'locked' &&
          <div>
          <input type='text' placeholder='Unlock' style={{textTransform: 'uppercase'}} onChange={e => {const basicHash = (s) => { var hash = 0; for (var i = 0; i < s.length; i++) { var char = s.charCodeAt(i); hash = ((hash<<5)-hash)+char; hash = hash & hash; } return hash; }; if ([388423089, -130212517].includes(basicHash(e.target.value.toLowerCase()))) this.changeJourneyStep('initial');}} />
         </div>
        }
        
        {currentlySettingUp &&
        <div style={{...boxStyle, ...(link1 && {cursor: 'pointer'})}} {...(link1 && {onClick: () => this.geoLocation.initialize()})}>
          {this.state.journey === 'initial' && 
            <span>{t('Where are you?', 'Wo bist du?')} <button className='button-with-language-ribbon'>{t('Send your position', 'Sende Position')}<LanguageRibbon /></button></span>
          }
          {['positionRequested', 'gotPosition'].includes(this.state.journey) && 
            <span>{t('Your position...', 'Deine Position...')} <ImSpinner8 style={{animation: 'icon-spin 2s infinite linear'}} /></span>
          }
          {this.state.journey === 'positionBlocked' && 
            <span>{t('Position blocked', 'Position blockiert')} <button>{t('Try again?', 'Erneut versuchen?')}</button></span>
          }
          {this.state.journey === 'gotPosition' && 
            <span></span>
          }
          {['gotAvailableGuides', 'guideSelected'].includes(this.state.journey) && 
            <span>{t('Your position', 'Deine Position')} <ImCheckmark /></span>
          }
          {this.state.journey === 'noGuidesAvailable' && 
            <span>{t('Sorry! No guides available right now, check back later', 'Keine Guides verfügbar, versuch es später nochmals')}</span>
          }
          {this.state.journey === 'error' && 
            <span>{t('Oops! Looks like there was a problem: {this.state.error}', 'Hoppla, es gab einen Fehler')}</span>
          }
        </div>
        }
        
        {currentlySettingUp &&
        <div style={{...boxStyle, ...(!active2 && inactiveBoxStyle)}}>
          {!active2 && 
           <span className='inactiveText'>{t('Select your guide', 'Wähle deinen Guide')}</span>
          }
          {this.state.journey === 'gotAvailableGuides' && 
            <span>{whichGuideButtons === 'here' ? t('Select one:', 'Deine Wahl:') : (whichGuideButtons === 'closeby' ? t('Nearby:', 'In der Nähe:') : (whichGuideButtons === 'farAway' ? t('A bit away:', 'Etwas weiter weg:') : null))}
            <GuideList guides={this.state.guides} onSelectGuide={guide => this.changeJourneyStep('guideSelected', {selectedGuide:guide})} />
            </span>
          }
          {this.state.journey === 'guideSelected' && 
            <span>{t('Your guide', 'Dein Guide')} <button disabled={true}>{renderGuideButton(this.state.selectedGuide, true)} <ImCheckmark /></button></span>
          }
        </div>
        }
        
        {currentlySettingUp && ['gotAvailableGuides', 'guideSelected'].includes(this.state.journey) && ['closeby', 'farAway'].includes(whichGuideButtons) &&
        <div style={infoStyle}>
          {t('For our interactive guides you need to be there. If that\'s not a good reason to travel, I don\'t know what is!', 'Für unsere interaktiven Guides musst du leider vor Ort sein. Wenn das mal kein guter Grund für eine Reise ist!')}
        </div>
        }
        
        {currentlySettingUp && !noGuidesRightHere &&
        <div style={{...boxStyle, ...(active3 && {cursor: 'pointer'}), ...(!active3 && inactiveBoxStyle)}} {...(active3 && {onClick: () => {this.audioPlayRef.current.startPlaying(); this.changeJourneyStep('play');}})}>
          {!active3 && 
           <span className='inactiveText'>{t('Put on your headphones', 'Zieh die Kopfhörer an')}</span>
          }
          {active3 && 
            <span>{t('Put on your headphones', 'Zieh die Kopfhörer an')} <button>{t('Start', 'Los geht\'s')}</button></span>
          }
        </div>
        }
        
        {(this.state.journey === 'play:outro' || this.state.journey === 'startover') &&
          <div style={{...boxStyle, ...(this.state.journey === 'startover' && {cursor: 'pointer'})}} onClick={() => {if (this.state.journey === 'startover') this.changeJourneyStep(this.jumpBackWhenStartingOver)}}>
          <span>{t('Go again?', 'Nochmals von vorne?')} <button disabled={this.state.journey === 'play:outro'}>{t('Start fresh', 'Zurück zum Start')}</button></span>
          </div>
        }
        
        {this.state.currentPosition && !noGuidesRightHere &&
          <CurrentPositionMap height={'200px'} position={this.state.currentPosition} />
        }
      </div>
    ); 
  }
}

export default App;