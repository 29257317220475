class ErrorHandler {
  
  static logStackTrace = false;
  
  constructor() {
    if (ErrorHandler._singleInstance) {
      return ErrorHandler._singleInstance;
    }
    this.observerCallbacks = [];
    ErrorHandler._singleInstance = this;
  }
  
  addObserver(callback) {
    this.observerCallbacks.push(callback);
  }
  
  handleError(message, originalError=null, additionalInformation=null) {
    if (ErrorHandler.logStackTrace) console.trace();
    
    if (originalError) console.error(message, originalError, additionalInformation);
    else console.error(message);
    
    this.observerCallbacks.forEach(callback => { callback(message); }); // call each callback function
  }
  
  static raise(...args) {
    ErrorHandler.singletonInstance().handleError(...args);
  }
  
  static singletonInstance() {
    return ErrorHandler._singleInstance || new ErrorHandler();
  }
}

export default ErrorHandler;