import React, { Component } from 'react';
import ErrorHandler from './ErrorHandler.js';
import './Popup.css';


class Popup extends Component {
  
  constructor() {
    super();
    
    this.interval = null;
    this.counterStart = 7;
    
    this.state = {
      showingList: []
    }
  }
  
  componentDidMount() {
    let errorHandler = ErrorHandler.singletonInstance();
    errorHandler.addObserver((error) => {
      this.addPopupWindow(error);
    });
  }
  
  addPopupWindow(item) {
    const randomid = Math.floor(Math.random()*1000000);
    this.setState(Object.assign({}, this.state, {showingList: [...this.state.showingList, [randomid, this.counterStart, item]]}));
    
    if (!this.interval) {
      this.interval = setInterval(() => {
        const updated = this.state.showingList.filter(x => x[1] > 1).map(x => [x[0], x[1]-1, x[2]]);
        if (updated.length === 0) this.stopTimer();
        
        this.setState(Object.assign({}, this.state, {showingList: updated}));
      }, 1000); // 1 second
    }
  }
  
  removePopupWindow(hashid) {
    const updated = this.state.showingList.filter(x => x[0] !== hashid);
    if (updated.length === 0) this.stopTimer();
    
    this.setState(Object.assign({}, this.state, {showingList: updated}));
  }
  
  stopTimer() {
    clearInterval(this.interval);
    this.interval = null;
  }
  
  render() {
    const popupWindows = this.state.showingList.map((x, i) => {
      let [hashid, counter, item] = x;
      return (
        <PopupWindow counter={counter} key={i} topPosition={(80+60*i) + 'px'} close={() => this.removePopupWindow(hashid)}>
        {item}
        </PopupWindow>
      );
    });
    return popupWindows;
  }
}


class PopupWindow extends Component {
  render() {
    return (
      <div className='modal'>
        <div className='modal-content' style={{top: this.props.topPosition}}>
          <span className='close-button' onClick={() => this.props.close()}>&times;</span>
          <span className='close-counter'>{this.props.counter}</span>
          {this.props.children}
        </div>
      </div>
    );
  }
};

export default Popup;